import { Table } from 'antd';
import { format } from 'date-fns-tz';

function AdminPharmacyActivityTable({ activities }) {
  const columns_pharmacy_activity = [
    {
      title: 'İşlem',
      dataIndex: 'note'
    },
    {
      title: 'İşlem Tipi',
      dataIndex: 'actionType'
    },
    {
      title: 'Kullanıcı E-postası',
      dataIndex: 'userEmail'
    },
    {
      title: 'Tarih',
      dataIndex: 'createdAt',
      render: (timeStamp) => {
        return format(new Date(timeStamp), 'dd.MM.yyyy HH:mm');
      }
    }
  ];

  return (
    <Table
      columns={columns_pharmacy_activity}
      dataSource={activities}
      rowKey="actionId"
      pagination={{
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30']
      }}
    />
  );
}

export default AdminPharmacyActivityTable;
