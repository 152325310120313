import '@assets/sharedStyles/formItems.scss';
import { getErrorMessage } from '@src/utils/errors';
import { axios_get } from '@utils/rest_axios';
import { Divider } from 'antd';
import { useEffect, useState } from 'react';
import { useNotificationPopup } from '../NotificationPopup/NotificationPopup';
import ApprovedCard from './ApprovedCard';
import ProblemCard from './ProblemCard';
import './RequestDetailCard.scss';
import CardHeader from './RequestDetailCardParts/CardHeader';
import PartButtons from './RequestDetailCardParts/PartButtons';

function getProcessedProducts(products) {
  return products.filter((product) => {
    return product.product_problem === null && product;
  });
}
function decideSetProblemProducts(products) {
  return products.filter((product) => product.product_problem !== null);
}
function getPayloadForFilter(type, data) {
  return type === 'STOCK' || type === 'PAP'
    ? data.pharmacy_products
    : data.pharmacy_kki_products;
}

function getUrl(requestId, pageType, type) {
  if (pageType === 'COMPANY') {
    return type === 'KKI'
      ? `/companypages/request-detail-kki/${requestId}`
      : `/companypages/request-detail-stock/${requestId}`;
  }
  if (pageType === 'PHARMACY') {
    return type === 'KKI'
      ? `/pharmacypages/request-detail-kki/${requestId}`
      : `/pharmacypages/request-detail-stock/${requestId}`;
  }
  if (pageType === 'ADMIN') {
    return type === 'KKI'
      ? `/admin/request-detail-kki/${requestId}`
      : `/admin/request-detail-stock/${requestId}`;
  }
}
const RequestDetailCard = ({
  setIsOpen,
  isOpen,
  requestId,
  moduleType,
  status,
  wholesaler,
  detail_card_type,
  pageType
}) => {
  const [tabs, setTabs] = useState('Accepted_Tab'); //"Accepted_Tab" || "Problem_Tab"
  const [processedProducts, setProcessedProducts] = useState([]);
  const [problemProducts, setProblemProducts] = useState([]);
  const [requestDate, setRequestDate] = useState('');
  const [papCode, setPapCode] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [requestNote, setRequestNote] = useState('');
  const { getNotification } = useNotificationPopup();

  useEffect(() => {
    async function getRequestDetail() {
      try {
        const url = getUrl(requestId, pageType, moduleType);
        const requestDetailRes = await axios_get(url);
        if (requestDetailRes.status === 200) {
          const payload = getPayloadForFilter(
            moduleType,
            requestDetailRes.data
          );
          const products = getProcessedProducts(payload);
          setProcessedProducts(products);
          setCompanyId(requestDetailRes.data.module?.company?.companyId);
          setRequestNote(requestDetailRes.data.requestNote);

          if (
            requestDetailRes.data?.pap_requests &&
            requestDetailRes.data.pap_requests.length > 0
          ) {
            const code =
              requestDetailRes.data.pap_requests[0]?.pap_code?.code ?? null;
            setPapCode(code);
          }
          const productsWithproblem = decideSetProblemProducts(payload);
          setProblemProducts(productsWithproblem);
          setRequestDate(requestDetailRes.data.applicationDate);
        }
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    }
    getRequestDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, requestId, moduleType]);

  const StatusMessage = () => {
    const statusMessageMap = new Map([
      [
        'PENDING',
        'Başvurunuza ait hesaplamalar tamamlanarak doğrulanmıştır. Ödemeniz başvurunuzu takip eden ayda yapılacaktır.'
      ],
      [
        'ACCEPTED',
        'Başvurunuza ilişkin ödemeniz, firma tarafından onaylanmıştır. Ödemenizi ilgili depo şubesinden takip edebilirsiniz.'
      ],
      ['REJECTED', 'Başvurunuz ilgili ilaç firması tarafından reddedilmiştir.'],
      ['PROBLEM', 'Başvurunuzda problem vardır.']
    ]);

    if (status) {
      if (status === 'REJECTED' && requestNote) {
        return (
          <div className="requestDetailCard_popup_tabs_sections_message">
            Ret Mesajı: {requestNote}
          </div>
        );
      }
      if (status === 'PROBLEM' && requestNote) {
        return (
          <div className="requestDetailCard_popup_tabs_sections_message">
            {requestNote}
          </div>
        );
      }
      return (
        <div className="requestDetailCard_popup_tabs_sections_message">
          {statusMessageMap.get(status)}
        </div>
      );
    }
    return null;
  };
  const CardSection = () => {
    if (tabs === 'Accepted_Tab') {
      return (
        <ApprovedCard
          moduleType={moduleType}
          status={status}
          processedProducts={processedProducts}
          wholesaler={wholesaler}
          papCode={papCode}
          pageType={pageType}
          requestId={requestId}
          companyId={companyId}
        />
      );
    } else if (tabs === 'Problem_Tab') {
      return (
        <ProblemCard
          moduleType={moduleType}
          problemProducts={problemProducts}
        />
      );
    }
  };

  return (
    <div className="requestDetailCard">
      <div className="requestDetailCard_container">
        <div className="requestDetailCard_popup">
          <PartButtons
            problemProducts={problemProducts}
            tabs={tabs}
            setTabs={setTabs}
          />
          <div className="requestDetailCard_popup_tabs_sections">
            <CardHeader
              requestId={requestId}
              requestDate={requestDate}
              setopen={setIsOpen}
              status={status}
            />
            <StatusMessage />
            <Divider style={{ margin: '5px 0' }} />
            <CardSection />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestDetailCard;
