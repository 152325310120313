import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  GLN_NUMBER_DIGIT_MESSAGE,
  GLN_NUMBER_PATTERN_MESSAGE,
  GLN_NUMBER_REGEX,
  GLN_NUMBER_REQUIRED_MESSAGE
} from '@src/utils/formRules/formRules';
import { axios_patch } from '@src/utils/rest_axios';
import { Button, Form, Input, Modal, message } from 'antd';

const { confirm } = Modal;

function WholesalerUpdateForm({ wholesaler, closeModal, setCallback }) {
  const [updateWholesalerForm] = Form.useForm();

  function SubmitForm(values) {
    confirmUpdate(values);
  }

  async function handleUpdateWholesaler(payload) {
    try {
      await axios_patch(
        `/companypages/wholesaler/${wholesaler.wholesalerId}`,
        payload
      );
      setCallback(true);
      closeModal();
      message.success('Depo başarıyla güncellendi!', 5);
    } catch (err) {
      message.error('Depo güncelleme başarısız!', 5);
    }
  }

  function confirmUpdate(values) {
    confirm({
      title: 'Kullanıcı Güncellenecek',
      icon: <ExclamationCircleOutlined />,
      content: 'Emin misiniz?',
      onOk() {
        handleUpdateWholesaler(values);
      }
    });
  }

  return (
    <Form
      form={updateWholesalerForm}
      name="updateWholesalerForm"
      initialValues={{
        name: wholesaler.name,
        gln: wholesaler.gln
        //city: wholesaler.city,
      }}
      onFinish={SubmitForm}
      autoComplete="off"
      colon={true}
      layout={'vertical'}
      className="Form"
    >
      <Form.Item
        name="name"
        label="Ecza Deposu Adı"
        rules={[
          {
            required: true,
            message: 'Ecza Deposu Adı giriniz'
          }
        ]}
      >
        <Input className="kemi_form_input_grey" placeholder="Ecza Deposu Adı" />
      </Form.Item>

      <Form.Item
        name="gln"
        label="GLN Numarası"
        rules={[
          {
            required: true,
            message: GLN_NUMBER_REQUIRED_MESSAGE
          },
          {
            min: 13,
            message: GLN_NUMBER_DIGIT_MESSAGE
          },
          {
            max: 13,
            message: GLN_NUMBER_DIGIT_MESSAGE
          },
          {
            pattern: GLN_NUMBER_REGEX,
            message: GLN_NUMBER_PATTERN_MESSAGE
          }
        ]}
      >
        <Input
          className="kemi_form_input_grey"
          placeholder="GLN Numarası"
          type="number"
        />
      </Form.Item>

      <Form.Item>
        <Button className="kemi_form_btn_dark" htmlType="submit">
          Güncelle
        </Button>
      </Form.Item>
    </Form>
  );
}

export default WholesalerUpdateForm;
