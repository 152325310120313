import { format } from 'date-fns-tz';

const { Table } = require('antd');

function AdminCompanyActivityTable({ activities }) {
  const companyActivitiesColumn = [
    {
      title: 'İşlem',
      dataIndex: 'note'
    },
    {
      title: 'İşlem tipi',
      dataIndex: 'actionType'
    },
    {
      title: 'İşlem içerigi',
      dataIndex: 'actionPayload'
    },
    {
      title: 'Kullanıcı E-postası',
      dataIndex: 'userEmail'
    },
    {
      title: 'Tarih',
      dataIndex: 'createdAt',
      render: (timeStamp) => {
        return format(new Date(timeStamp), 'dd.MM.yyyy HH:mm');
      }
    }
  ];

  return (
    <Table
      columns={companyActivitiesColumn}
      dataSource={activities}
      rowKey="actionId"
      pagination={{
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30']
      }}
    />
  );
}
export default AdminCompanyActivityTable;
