import '@assets/sharedStyles/modal.scss';
import {
  EMAIL_MESSAGE,
  EMAIL_REQUIRED,
  GLN_NUMBER_DIGIT_MESSAGE,
  GLN_NUMBER_PATTERN_MESSAGE,
  GLN_NUMBER_REGEX,
  GLN_NUMBER_REQUIRED_MESSAGE
} from '@src/utils/formRules/formRules';
import { axios_post_public } from '@src/utils/rest_axios';
import { Button, Form, Input, Modal } from 'antd';
import { useState } from 'react';
import { GrFormClose } from 'react-icons/gr';

const PharmacyLoginIssuesFlowModals = ({
  isModalVisible,
  errorMessage,
  handleModalVisible,
  inactivePharmacyValues
}) => {
  const [step, setStep] = useState(0);
  const [glnRequestResponse, setGlnRequestResponse] = useState();
  const [formGLNRequest] = Form.useForm();
  const [formPasswordResetRequest] = Form.useForm();

  const [isPasswordResetRequestError, setPasswordResetRequestError] =
    useState(false);

  const handleGLNRequest = async (value) => {
    try {
      const response = await axios_post_public('/gln', { gln: value.gln });
      setGlnRequestResponse(response.data);
    } catch (error) {
      throw error;
    } finally {
      setStep(1);
    }
  };

  const handlePasswordResetRequest = async (value) => {
    try {
      const response = await axios_post_public(
        '/users/reset-password-request',
        value
      );
      if (response.status === 200) setStep(1);
    } catch (error) {
      if (error.response.data?.name === 'NoUserFoundError') {
        setPasswordResetRequestError(true);
      }
      throw error;
    } finally {
    }
  };

  const steps = {
    AccountNotActiveError: [
      {
        step: 0,
        title: 'Aktif Edilmemiş Üyelik',
        context: (
          <div className="modal-context-container">
            <p className="modal-context-info">
              Üyeliğiniz aktif görünmemektedir. İTS'ye kayıtlı olan{' '}
              {inactivePharmacyValues?.email} adresinize tekrardan aktivasyon
              linki yönlendirilmiştir. Spam/gereksiz klasörünüzü kontrol etmeyi
              unutmayınız. Linkin geçerliliği 1 gündür.
            </p>
            <div className="modal-context-footer">
              <Button
                className="modal-button"
                onClick={() => handleModalVisible((value) => !value)}
              >
                Tamam
              </Button>
            </div>
          </div>
        )
      }
    ],
    WrongCredentialsError: [
      {
        step: 0,
        title: 'Hatalı Şifre',
        context: (
          <div className="modal-context-container">
            <p className="modal-context-info">
              Şifre sıfırlama talebi gönderebilirsiniz.
            </p>
            <Form
              className="modal-context-form"
              form={formPasswordResetRequest}
              onFinish={handlePasswordResetRequest}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    ...EMAIL_REQUIRED,
                    message: EMAIL_MESSAGE
                  }
                ]}
              >
                <Input type="email" placeholder="example@domain.com" />
              </Form.Item>
            </Form>
            {isPasswordResetRequestError ? (
              <span
                style={{
                  color: 'red',
                  marginTop: '-10px'
                }}
              >
                Bu e-posta adresine ait bir kullanıcı bulunamadı.
              </span>
            ) : null}
            <div className="modal-context-footer">
              <Button
                className="modal-button"
                onClick={() => formPasswordResetRequest.submit()}
              >
                Gönder
              </Button>
            </div>
          </div>
        )
      },
      {
        step: 1,
        title: 'Hatalı Şifre',
        context: (
          <div className="modal-context-container">
            <p className="modal-context-info">
              Mail adresinize şifre sıfırlama linki gönderilmiştir.
              Spam/gereksiz klasörünü kontrol etmeyi unutmayınız.
            </p>
            <div className="modal-context-footer">
              <Button
                className="modal-button"
                onClick={() => {
                  handleModalVisible((value) => !value);
                  setGlnRequestResponse(null);
                  setStep(0);
                }}
              >
                Tamam
              </Button>
            </div>
          </div>
        )
      }
    ],
    NoUserFoundError: [
      {
        step: 0,
        title: 'Kayıtlı Olmayan E-posta',
        context: (
          <div className="modal-context-container">
            <p className="modal-context-info">
              Bu e-posta adresine bağlı bir üyelik bulunmamaktadır.
            </p>
            <Form
              className="modal-context-form"
              form={formGLNRequest}
              onFinish={handleGLNRequest}
            >
              <Form.Item
                name="gln"
                rules={[
                  {
                    required: true,
                    message: GLN_NUMBER_REQUIRED_MESSAGE
                  },
                  {
                    min: 13,
                    message: GLN_NUMBER_DIGIT_MESSAGE
                  },
                  {
                    max: 13,
                    message: GLN_NUMBER_DIGIT_MESSAGE
                  },
                  {
                    pattern: GLN_NUMBER_REGEX,
                    message: GLN_NUMBER_PATTERN_MESSAGE
                  }
                ]}
              >
                <Input placeholder="868XXXXXXXXXX" />
              </Form.Item>
            </Form>
            <div className="modal-context-footer">
              <Button
                className="modal-button"
                onClick={() => formGLNRequest.submit()}
              >
                GLN ile kontrol et
              </Button>
            </div>
          </div>
        )
      },
      {
        step: 1,
        title: 'Kayıtlı Olmayan E-posta',
        context: (
          <div className="modal-context-container">
            {glnRequestResponse ? (
              <p className="modal-context-info">
                Bu GLN ile ilişkilendirilmiş mail adresi{' '}
                {glnRequestResponse.email} olarak görünmektedir. Değişiklik
                ihtiyacı halinde sağ alt köşede bulunan destek hattından bizimle
                iletişime geçiniz.
              </p>
            ) : (
              <p className="modal-context-info">
                Bu GLN ile eşleşen bir üyelik bulunmamaktadır. Giriş yapabilmek
                için öncelikle Kaydol butonundan üye olup üyeliğinizi aktive
                etmeniz gerekmektedir.
              </p>
            )}
            <div className="modal-context-footer">
              <Button
                className="modal-button"
                onClick={() => {
                  handleModalVisible((value) => !value);
                  setGlnRequestResponse(null);
                  setStep(0);
                }}
              >
                Tamam
              </Button>
            </div>
          </div>
        )
      }
    ]
  };

  const currentStep = steps[errorMessage] ? steps[errorMessage][step] : null;

  const CloseIcon = () => (
    <div className="close-icon-container">
      <GrFormClose size={25} color="#fffafe" />
    </div>
  );

  return (
    <Modal
      className="modal"
      centered
      open={isModalVisible}
      closeIcon={<CloseIcon />}
      footer={null}
      title={currentStep?.title}
      onCancel={() => handleModalVisible()}
    >
      {currentStep && currentStep.context}
    </Modal>
  );
};

export default PharmacyLoginIssuesFlowModals;
