import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  SyncOutlined
} from '@ant-design/icons';
import { formatDateToUTCString } from '@src/utils/date.helpers';
import { statusMap } from '@src/utils/staticJsonObjects/status';
import { Row, Tag } from 'antd';

export default function CardHeader({
  requestId,
  requestDate,
  setopen,
  status
}) {
  function handleStatusTags() {
    const statusIconMap = new Map([
      ['PENDING', [<SyncOutlined spin />, 'processing']],
      ['ACCEPTED', [<CheckCircleOutlined />, 'success']],
      ['REJECTED', [<CloseCircleOutlined />, 'error']],
      ['PROBLEM', [<ExclamationCircleOutlined />, 'warning']]
    ]);
    if (!status) {
      return '-';
    }
    return (
      <Tag
        className="requestDetailCard_popup_tabs_sections_tag"
        icon={statusIconMap.get(status)[0]}
        color={statusIconMap.get(status)[1]}
      >
        {statusMap.get(status)}
      </Tag>
    );
  }
  const StatusTags = () => {
    return (
      <div className="requestDetailCard_popup_header_req_info">
        <div className="request_number_text">
          <div className="requestDetailCard_popup_header_req_info_title">
            <span className="status_text">Durum</span>
          </div>
          <div className="requestDetailCard_popup_header_req_info_content">
            {handleStatusTags(status)}
          </div>
        </div>
      </div>
    );
  };

  const CardHeaderRequestNoInfo = () => {
    return (
      <div>
        <h1 className="request_number_text">
          BAŞVURU NO <br />
          <span>{requestId ?? '-'}</span>
        </h1>
      </div>
    );
  };

  const CardHeaderRequestDate = () => {
    return (
      <div className="requestDetailCard_popup_header_req_info">
        <div className="request_number_text">
          <div className="requestDetailCard_popup_header_req_info_title">
            Başvuru Tarihi
          </div>
          <div className="requestDetailCard_popup_header_req_info_content">
            <span>
              {formatDateToUTCString(new Date(+requestDate), 'dd.MM.yyyy') ??
                '-'}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const CardHeaderCloseButton = () => {
    return (
      <div className="requestDetailCard_popup_close_btn_container">
        <button
          onClick={() => setopen(false)}
          className="kemi_form_icon_button_ghost "
        >
          <CloseOutlined className="requestDetailCard_popup_close_btn" />
        </button>
      </div>
    );
  };

  return (
    <div className="requestDetailCard_popup_header">
      <Row
        justify="space-between"
        className="requestDetailCard_popup_close_btn_container_relative_parent"
      >
        <Row justify="start">
          <CardHeaderRequestNoInfo />
          <StatusTags />
          <CardHeaderRequestDate />
        </Row>
        <CardHeaderCloseButton />
      </Row>
    </div>
  );
}
