import CreateWholesaler from '@Components/company_components/wholesaler/CreateWholesaler/CreateWholesaler';
import '@assets/sharedStyles/pageItems.scss';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import ReadExcelFromXls from '@src/components/shared/ReadExcelFromXls/ReadExcelFromXls';
import { getErrorMessage } from '@src/utils/errors';
import { axios_get } from '@src/utils/rest_axios';
import { Collapse, Divider, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { ExampleXlsxDownload } from './ExampleXlsxDownload';
import './ManageWarehouse.scss';
import { WholesalerRow } from './WholesalerRow';

const { Panel } = Collapse;

const ManageWarehouse = () => {
  const [wholesalers, setWholesalers] = useState([]);
  const [callback, setCallback] = useState(false); // after creating new wholesaler successfully, refreshes the table

  const { getNotification } = useNotificationPopup();
  const columns = [
    {
      title: 'Depo GLN',
      dataIndex: 'gln'
    },
    {
      title: 'Depo Adı',
      dataIndex: 'name'
    },

    {
      title: ' ',
      render: (text, row) => (
        <WholesalerRow
          text={text}
          row={row}
          setCallback={setCallback}
          callback={callback}
        />
      )
    }
  ];

  useEffect(() => {
    async function getWholesalers() {
      try {
        const wholesalerRes = await axios_get('/companypages/wholesaler');
        if (wholesalerRes.data.length > 0) {
          setWholesalers(
            wholesalerRes.data.map((row) => {
              //TODO buradaki sehir backend degisince degisecek
              return { ...row,  };
            })
          );
        }
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    }
    getWholesalers();

    return () => {
      setCallback(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback]);

  // jsx parts
  // function DownloadListedWarehouses() {
  //   // Hidden now
  //   // TODO download from table this can be integrated to table component with probs
  //   return (
  //     <Row justify="end">
  //       <Button className="kemi_form_btn_purple manageWarehouse_buttons">
  //         .xls indir
  //       </Button>
  //     </Row>
  //   );
  // }

  return (
    <div className="manageWarehouse">
      <div className="manageWarehouse__header">
        <div className="manageWarehouse_context">
          <Collapse accordion>
            <Panel header="Tek tek depo ekleme" key="1">
              <CreateWholesaler setCallback={setCallback} callback={callback} />
            </Panel>
            <Panel header="Dosya yükleme ile çoklu depo ekleme" key="2">
              <ExampleXlsxDownload />
              <Divider />
              <Row justify="center">
                <ReadExcelFromXls
                  setCallback={setCallback}
                  callback={callback}
                />
              </Row>
            </Panel>
          </Collapse>
          <div
            style={{
              backgroundColor: '#eaedf7',
              height: '30px'
            }}
          ></div>
          <div>
            <Table
              columns={columns}
              dataSource={wholesalers}
              rowKey="wholesalerId"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageWarehouse;
