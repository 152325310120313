import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import TableComponent from '@src/components/shared/TableComponent/TableComponent';
import { formatDateToUTCString } from '@src/utils/date.helpers';
import { getErrorMessage } from '@src/utils/errors';
import { formatTurkishCurrency } from '@src/utils/numberFormats';
import { axios_post } from '@utils/rest_axios';
import { statusMap } from '@utils/staticJsonObjects/status';
import { useState } from 'react';
import AdminPharmacyRequestsFilter from '../AdminPharmacyRequestFilters/AdminPharmacyRequestsFilter';
import './AdminKKIActiveRequests.scss';
// import uniqBy from "lodash/uniqBy";

const detailCardType = 'admin';

function AdminKKIActiveRequests() {
  const [filterParameters, setFilterParameters] = useState({});
  const [requestRows, setRequestRows] = useState([]);
  const [requestLoading, setRequestLoading] = useState(false);
  // const [priceOptions, setPriceOptions] = useState([])
  const { getNotification } = useNotificationPopup();

  const fetchRequests = async (parameters, paginationPage) => {
    setRequestLoading(true);
    try {
      const response = await axios_post(`/admin/pharmacy-requests`, {
        ...(parameters ? parameters : filterParameters),
        offset: paginationPage ? paginationPage : 0,
        moduleType: 'KKI'
      });
      // TODO ADD PRICE FILTER
      // setPriceOptions(uniqueOptions)
      setRequestRows(response.data);
    } catch ({ response }) {
      if (response && response.status !== 401) {
        getNotification({
          ...getErrorMessage(response)
        });
      }
    } finally {
      setRequestLoading(false);
    }
  };

  const columns = [
    {
      title: 'Başvuru No',
      dataIndex: 'requestId',
      render: (text, row) => row.requestId
    },
    {
      title: 'Modül Adı',
      dataIndex: ['module', 'name']
    },
    {
      title: 'Eczane GLN',
      dataIndex: ['pharmacy', 'gln']
    },
    {
      title: 'Eczane Adı',
      dataIndex: ['pharmacy', 'name']
    },
    {
      title: 'Şehir',
      dataIndex: ['pharmacy', 'address', 'city', 'cityName']
    },
    {
      title: 'Başvuru Tarihi',
      dataIndex: 'applicationDate',
      render: (text) => {
        const date = new Date(Number(text));
        return formatDateToUTCString(date, 'dd.MM.yyyy');
      }
    },
    {
      title: 'Tutar',
      dataIndex: 'totalPayment',
      render: (text) => formatTurkishCurrency(text, 2)
      // filters: priceOptions,
      // filterMode: 'tree',
      // onFilter: (value , record) => record.totalPayment === value,
    },
    {
      title: 'Ödeme Durumu',
      dataIndex: 'requestStatus',
      render: (text) => statusMap.get(text)
    }
  ];

  return (
    <div>
      <AdminPharmacyRequestsFilter
        type={'KKI'}
        parameters={filterParameters}
        setParameters={setFilterParameters}
        fetchRequests={fetchRequests}
      />
      <TableComponent
        detailCardType={detailCardType}
        moduleType="KKI"
        page_type="ADMIN"
        columns={columns}
        fetchRequests={fetchRequests}
        data_arr={requestRows}
        requestLoading={requestLoading}
        filterParameters={filterParameters}
      />
    </div>
  );
}

export default AdminKKIActiveRequests;
