import ApprovedKKI from './RequestDetailCardParts/KKİ/ApprovedKKI';
import { ApprovedStock } from './RequestDetailCardParts/Stock/ApprovedStock';

const ApprovedCard = ({
  moduleType,
  status,
  processedProducts,
  wholesaler,
  papCode,
  pageType,
  requestId,
  companyId
}) => {
  return (
    <div className="requestDetailCard_popup_tabs_sections_content">
      {moduleType === 'KKI' ? (
        <ApprovedKKI processedProducts={processedProducts} />
      ) : (
        <ApprovedStock
          processedProducts={processedProducts}
          status={status}
          wholesaler={wholesaler}
          papCode={papCode}
          pageType={pageType}
          requestId={requestId}
          companyId={companyId}
        />
      )}
    </div>
  );
};

export default ApprovedCard;
