import '@assets/sharedStyles/formItems.scss';
import '@assets/sharedStyles/pageItems.scss';
import { ModuleNote } from '@src/components/admin_components/ModuleNote/ModuleNote';
import { formatDateToUTCString } from '@src/utils/date.helpers';
import { Collapse, Divider, Empty, Row } from 'antd';
import AddModulePrice from '../stockloss_kki_updates/AddModulePrice/AddModulePrice';
import AddProductControl from '../stockloss_kki_updates/AddProductControl/AddProductControl';
import UpdateModuleControlPrice from '../stockloss_kki_updates/UpdateModuleControlPrice/UpdateModuleControlPrice';
import UpdateProductControl from '../stockloss_kki_updates/UpdateProductControl/UpdateProductControl';
import UpdateProductName from '../stockloss_kki_updates/UpdateProductName/UpdateProductName';
import './ModuleList.scss';
import ModuleSwitchButton from './ModuleSwitchButton';

const { Panel } = Collapse;

function getPanelHeader(module, setReload, reload) {
  return (
    <div className="module-list__header">
      <Row justify="space-between" className="module-list__grid_field">
        <div className="module-list__name">{`${module.name}`}</div>
        <div className="module-list__name collapse_infos">
          <div className="collapse_infos_item">
            <ModuleSwitchButton
              reload={reload}
              moduleId={module.moduleId}
              current_status={module.status}
              setReload={setReload}
            />
          </div>
          <div className="collapse_infos_item">
            {formatDateToUTCString(new Date(module.createdAt), 'dd.MM.yyyy')}
          </div>
        </div>
      </Row>
    </div>
  );
}
function getUpdateProductName(module, setReload, reload) {
  return (
    <UpdateProductName
      product_name={
        module.module_company_products[0]
          ? module.module_company_products[0].company_product.name
          : ''
      }
      companyProducts={module.module_company_products}
      reload={reload}
      callBack={setReload}
    />
  );
}
function getUpdateProductControl(module, setReload, reload) {
  return (
    module.module_company_products.length > 0 &&
    module.module_company_products.map((moduleCompanyProduct) => {
      return (
        <div key={moduleCompanyProduct.modulesProductCompanyId}>
          <UpdateProductControl
            companyProduct={moduleCompanyProduct.company_product}
            reload={reload}
            callBack={setReload}
          />
        </div>
      );
    })
  );
}
function getPricesAndDates(module, setReload, reload, type) {
  return (
    module.module_controls.length > 0 &&
    module.module_controls.map((module_control) => {
      return (
        <UpdateModuleControlPrice
          key={module_control.moduleControlId}
          module_control={module_control}
          callBack={setReload}
          reload={reload}
          type={type}
        />
      );
    })
  );
}
function getCollapseElements(modules, setReload, reload, type) {
  if (modules.length > 0) {
    return modules.map((module) => (
      <Panel
        header={getPanelHeader(module, setReload, reload)}
        key={module.moduleId}
      >
        <div className="module-list kemi_form_background">
          {/* medicine name */}
          {getUpdateProductName(module, setReload, reload)}

          {/* gtins & product controls */}
          {getUpdateProductControl(module, setReload, reload)}

          <AddProductControl
            module={module}
            callBack={setReload}
            reload={reload}
          />
          <Divider />
          {/* module_controls & price range */}
          {getPricesAndDates(module, setReload, reload, type)}
          <AddModulePrice
            module={module}
            callBack={setReload}
            reload={reload}
          />

          <Divider />
          {/* Module note */}

          <ModuleNote module={module} callBack={setReload} reload={reload} />
        </div>
      </Panel>
    ));
  } else {
    return (
      <div>
        <Empty description="Bilgi Yok" />
      </div>
    );
  }
}

/**
 * TODO
 * Module date (in the heading) not in module respond. Rendering first price beginning date for now
 *
 */
const ModulesList = ({ type, setReload, modules, reload }) => {
  return (
    <div className="module-list kemi_collapse">
      <Row justify="end">
        <b className="module-list-text">Durum</b>
        <b className="module-list-text">Oluşturulma Tarihi</b>
      </Row>
      <Collapse>
        {getCollapseElements(modules, setReload, reload, type)}
      </Collapse>
    </div>
  );
};

export default ModulesList;
