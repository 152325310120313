import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import { axios_get } from '@src/utils/rest_axios';
import { Table, Input, Space, Button } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { ResendVerifyAccountLink } from './ResendVerifyAccountLink';
import UserDelete from './UserDelete';
import UserUpdate from './UserUpdate';
import _ from 'lodash';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

function UsersTable() {
    const [usersList, setUsersList] = useState([]);
    const { getNotification } = useNotificationPopup();
    const [refresh, setRefresh] = useState(false);
    useEffect(() => {
        async function getUsers() {
            try {
                const usersRes = await axios_get('/admin/users');
                if (usersRes.data.length > 0) {
                    setUsersList(usersRes.data);
                }
            } catch ({ response }) {
                if (response && response.status !== 401) {
                    getNotification({
                        ...getErrorMessage(response),
                    });
                }
            }
        }
        getUsers();
        return () => setRefresh(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    // column search logic
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={e => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Ara
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Sıfırla
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: true });
                        }}
                    >
                        Kapat
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => {
            const dataValue = _.get(record, dataIndex);
            return dataValue && dataValue.toString().toLowerCase().includes(value.toLowerCase());
        },

        onFilterDropdownOpenChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: text =>
            _.isEqual(searchedColumn, dataIndex) ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const userColumns = [
        {
            title: 'Kullanıcı Tipi',
            dataIndex: ['role', 'name'],
        },
        {
            title: 'Kullanıcı İsmi',
            dataIndex: 'name',
        },
        {
            title: 'Kullanıcı E-posta',
            dataIndex: 'email',
        },
        {
            title: 'Telefon',
            dataIndex: 'phone',
        },
        {
            title: 'isActive',
            dataIndex: 'isActive',
        },
        {
            title: 'gln',
            dataIndex: ['pharmacy', 'gln'],
            ...getColumnSearchProps(['pharmacy', 'gln']),
        },
        {
            title: 'Eczane İsmi',
            dataIndex: ['pharmacy', 'name'],
        },
        {
            title: 'Kayıt Tarihi',
            dataIndex: ['createdAt'],
            sorter: (a, b) => {
                function formattedDate(dateString) {
                    const parts = dateString.split(/\.|-|:/);
                    const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]} ${parts[3]}:${parts[4]}:${parts[5]}`;
                    const date = new Date(formattedDate);
                    return date;
                }

                const date1 = formattedDate(a.createdAt);
                const date2 = formattedDate(b.createdAt);

                return date1 - date2;
            },
        },
        {
            title: 'İletişim Onayı',
            dataIndex: 'dataprotection',
            render: val => (
                <div className="">
                    {val?.emailConsent === true ? (
                        <button className="kemi_form_icon_button_ghost">
                            <CheckCircleOutlined />
                        </button>
                    ) : (
                        <button className="kemi_form_icon_button_ghost">
                            <ExclamationCircleOutlined />
                        </button>
                    )}
                </div>
            ),
        },

        {
            title: ' ',
            render: (text, row) => (
                <div className="">
                    <button className="kemi_form_icon_button_ghost">
                        <UserUpdate user={row} setRefresh={setRefresh} />
                    </button>
                    <button className="kemi_form_icon_button_ghost">
                        <UserDelete userId={row.userId} setRefresh={setRefresh} />
                    </button>
                    {row.role.name === 'Pharmacist' && (
                        <button className="kemi_form_icon_button_ghost">
                            <ResendVerifyAccountLink
                                userId={row.userId}
                                setRefresh={setRefresh}
                                pharmacyId={row.pharmacy.pharmacyId}
                            />
                        </button>
                    )}
                </div>
            ),
        },
    ];

    return (
        <Table
            columns={userColumns}
            dataSource={usersList}
            rowKey="userId"
            pagination={{
                pageSizeOptions: [300, 500, 1000],
                defaultPageSize: 500,
            }}
        />
    );
}

// table column structure

export default UsersTable;
