import { apiInstance } from '@src/api/axiosConfig';
import { usePost } from '@src/api/methods';
import { formatDateToUTCString } from '@src/utils/date.helpers';
import { formatTurkishCurrency } from '@src/utils/numberFormats';
import { useQuery } from '@tanstack/react-query';
import { Button, Divider, Input, message, Select } from 'antd';
import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
const useWholesalerSelection = (companyId, initialWholesaler, shouldFetch) => {
  const [selectedWholesaler, setSelectedWholesaler] =
    useState(initialWholesaler);
  const [isWholesalerSelectClicked, setIsWholesalerSelectClicked] =
    useState(false);

  const { data, isLoading } = useQuery({
    queryKey: ['companywholesaler', companyId],
    queryFn: () =>
      apiInstance
        .get(`/pharmacypages/companywholesaler/${companyId}`)
        .then((res) => res.data),
    enabled: shouldFetch && !!companyId,
    onError: (error) => {
      console.error('Error fetching wholesaler data:', error);
    }
  });
  const wholesalerOptions = useMemo(() => {
    return data
      ?.sort((a, b) => a.name.localeCompare(b.name))
      .map((wholesaler) => ({
        value: wholesaler.wholesalerId,
        label: wholesaler.name
      }));
  }, [data]);

  return {
    selectedWholesaler,
    setSelectedWholesaler,
    isWholesalerSelectClicked,
    setIsWholesalerSelectClicked,
    wholesalerOptions,
    isLoading
  };
};

export const ProductTable = ({ products }) => {
  return (
    <table>
      <thead>
        <tr>
          <th className="tr-head">Karekod</th>
          <th className="tr-head">Alış Tarihi</th>
          <th className="tr-head">Satış Tarihi</th>
          <th className="tr-head">Tutar (KDV Hariç)</th>
        </tr>
      </thead>
      <tbody>
        {products?.map((product) => (
          <tr key={uuidv4()}>
            <td>
              <Input
                className="tr-input"
                readOnly
                type="text"
                value={product?.barcode ?? '-'}
              />
            </td>
            <td>
              {product?.product_detail?.pharmacyBuyDate
                ? formatDateToUTCString(
                    new Date(+product.product_detail.pharmacyBuyDate),
                    'dd.MM.yyyy'
                  )
                : '-'}
            </td>
            <td>
              {product?.product_detail?.pharmacySaleDate
                ? formatDateToUTCString(
                    new Date(+product.product_detail.pharmacySaleDate),
                    'dd.MM.yyyy'
                  )
                : '-'}
            </td>
            <td>
              <span className="th-price-tag">
                {product?.paymentAmount
                  ? formatTurkishCurrency(product?.paymentAmount, 2)
                  : '-'}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const ApprovedStock = ({
  processedProducts,
  wholesaler,
  papCode,
  requestId,
  pageType,
  status,
  companyId
}) => {
  const isCompanyUser = pageType === 'COMPANY';

  const {
    selectedWholesaler,
    setSelectedWholesaler,
    isWholesalerSelectClicked,
    setIsWholesalerSelectClicked,
    wholesalerOptions,
    isLoading
  } = useWholesalerSelection(
    companyId,
    wholesaler?.name ?? null,
    !isCompanyUser
  );

  const { mutate, isPending: isUpdateRequestWholesalerPending } =
    usePost('request-wholesaler');

  const handleWholesalerSelectOnChange = (value) => {
    setSelectedWholesaler(value);
    setIsWholesalerSelectClicked(true);
  };

  const handleWholesalerSaveButtonOnClick = () => {
    setIsWholesalerSelectClicked(true);
    const wholesalerIdAndRequest = {
      wholesalerId: selectedWholesaler,
      requestId
    };
    mutate(wholesalerIdAndRequest, {
      onSuccess: (_, variables) => {
        setSelectedWholesaler(variables.wholesalerId);
        setIsWholesalerSelectClicked(false);
        message.success('Depo başarıyla değiştirildi.');
      },
      onError: () => {
        setSelectedWholesaler(null);
        message.error(
          'Depo değiştirilirken bir hata oluştu. Lütfen tekrar deneyin.'
        );
      }
    });
  };
  return (
    <div className="requestDetailCard_popup_table_container">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '1rem',
          gap: '0.5rem'
        }}
      >
        {papCode && (
          <div style={{ marginTop: '1rem' }}>
            <span
              style={{
                color: 'white',
                backgroundColor: 'rgb(100 116 139)',
                borderRadius: '0.375rem',
                padding: '0.5rem',
                marginRight: '0.5rem'
              }}
            >
              Promosyon Kodu:
            </span>
            <span>{papCode}</span>
          </div>
        )}

        <div style={{ marginTop: '1rem' }}>
          <span
            style={{
              color: 'white',
              backgroundColor: 'rgb(100 116 139)',
              borderRadius: '0.375rem',
              padding: '0.5rem',
              marginRight: '0.5rem',
              marginTop: '0.5rem'
            }}
          >
            Depo Adı:
          </span>
          {pageType === 'COMPANY' || status !== 'PENDING' ? (
            <span>{wholesaler?.name}</span>
          ) : (
            <Select
              style={{ width: '24rem' }}
              value={selectedWholesaler}
              options={wholesalerOptions}
              onChange={handleWholesalerSelectOnChange}
              loading={isLoading}
            />
          )}
          {isWholesalerSelectClicked && (
            <Button
              type="primary"
              style={{ marginLeft: '1rem' }}
              onClick={handleWholesalerSaveButtonOnClick}
              loading={isUpdateRequestWholesalerPending}
            >
              Kaydet
            </Button>
          )}
        </div>
        <Divider style={{ margin: '5px 0' }} />
      </div>
      <ProductTable products={processedProducts} />
    </div>
  );
};
