import { Table } from 'antd';
import { format } from 'date-fns-tz';

function AdminActivityTable({ activities }) {
  const columns_admin_activity = [
    {
      title: 'İşlem',
      dataIndex: 'note'
    },
    {
      title: 'Kullanıcı E-postası',
      dataIndex: 'userEmail'
    },
    {
      title: 'Tarih',
      dataIndex: 'createdAt',
      render: (timeStamp) => {
        return format(new Date(timeStamp), 'dd.MM.yyyy HH:mm');
      }
    }
  ];

  return (
    <Table
      columns={columns_admin_activity}
      dataSource={activities}
      rowKey="actionId"
      pagination={false}
    />
  );
}

export default AdminActivityTable;
